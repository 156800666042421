.audioprofiles-header {
  grid-column-start: 1;
  grid-column-end: span 4;
  grid-row-start: 1;
  grid-row-end: 1;
  border: 10px solid rgb(162, 107, 107);
  border-radius: 10px;
  padding: 15px;
  font-family: "Open Sans";
  font-size: 30px;
  color: white;
  text-align: center;
  background: rgb(0, 0, 0);
  margin-top: 0px;
}

.audioprofiles-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: -10px;
  min-width: 150px;
  background-color: white;
  border-left: solid;
  border-right: solid;
  border-color: rgb(158, 158, 158);
  padding: 20px;
}

.audioprofiles-title {
  font: "Roboto";
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.text-above-graph {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
}

.TwoD_Select {
  border-width: 2px;
  border-color: black;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  font: "Roboto";
}
.TwoD_Select option:checked {
  background: rgb(255 205 0);
}

.ThreeD_Select {
  margin-top: 2%;
  border-width: 2px;
  border-color: black;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  font: "Roboto";
  background-color: white;
}

.ThreeD_Select option {
  color: black;
  background-color: white;
}

.ThreeD_Select option:checked {
  background: rgb(255 205 0);
}
