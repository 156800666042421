.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 10px solid rgb(0, 0, 0);
  /* border-radius: 10px; */
  /* padding: 15px; */
  font-family: "Open Sans";
  font-size: 60px;
  color: white;
  background: rgb(0, 0, 0);
  margin-top: -25px;
  margin-bottom: -50px;
  padding-bottom: 35px;
  padding-top: 15px;
}

.App-link {
  color: #61dafb;
}

.App-ul {
  margin-top: 20px;
  margin-left: -30px;
  margin-right: 20px;
  color: rgb(255, 205, 0);
}

.App-ul2 {
  margin-top: 20px;
  margin-left: -70px;
  margin-right: 20px;
  color: rgb(255, 205, 0);
}

.App-a:link {
  color: rgb(255 205 0);
  font-size: 20px;
}
.App-a:visited {
  color: rgb(255 205 0);
}
.App-a:hover {
  color: white;
}
.App-a:active {
  color: white;
}

.App-a2:link {
  color: rgb(255 205 0);
  font-size: 20px;
}
.App-a2:visited {
  color: rgb(255 205 0);
}
.App-a2:hover {
  color: white;
}
.App-a2:active {
  color: white;
}
.App-a2 {
  margin-left: -10px;
}

.App-pic {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.App-pic2 {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  height: auto;
  justify-content: center;
  margin-top: 0px;
  margin-left: -100px;
}
