.myAccount-header {
  border: 2px solid rgb(162, 107, 107);
  border-radius: 5px;
  color: rgb(255, 205, 0);
  padding: 15px;
  font: "Roboto";
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  background: black;
  margin-top: -10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  width: 80%;
}

.myAccount-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: -10px;
  min-width: 150px;
  background-color: white;
  border: 5px;
  border-left: solid;
  border-right: solid;
  border-color: rgb(158, 158, 158);
  padding: 20px;
}

.myAccount-title {
  font: "Roboto";
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.body-text {
  display: flex;
  flex-direction: row;
  border-bottom: 5px solid;
  border-color: black;
}

.body-shape {
  display: flex;
  flex-direction: column;
  border: 20px solid black;
  border-radius: 10px;
  /* padding: 10px; */
  text-align: center;
  background-color: rgb(160, 160, 160);
  width: 70%;
}
.body-title {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 36pt;
  background-color: rgb(160, 160, 160);
  color: rgb(255, 205, 0);
  border-right: 5px solid;
  border-color: black;
  padding: 15px;
  width: 30%;
}
.body-info {
  display: flex;
  align-items: center;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 24pt;
  padding: 15px;
}

.row-container {
  border: 10px solid;
  border-radius: 10px;
  border-color: rgb(255, 205, 0);
  padding: 20px;
  width: 400px;
  font-family: "Roboto";
  font-size: 10pt;
  background-color: rgb(235, 235, 235);
}
.row-container2 {
  border: 10px solid;
  border-radius: 10px;
  border-color: rgb(0, 0, 0);
  padding: 20px;
  width: 400px;
  font-family: "Roboto";
  font-size: 10pt;
  background-color: rgb(235, 235, 235);
}
.row-container3 {
  border: 10px solid;
  border-radius: 10px;
  border-color: rgb(0, 0, 0);
  padding: 5px;
  width: 400px;
  font-family: "Roboto";
  font-size: 10pt;
  background-color: rgb(235, 235, 235);
}
.user-account-rows {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* align-items: center; */
  justify-content: center;
}
.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
  background-color: rgb(235, 235, 235);
}
.title-container2 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: -40px;
  background-color: rgb(235, 235, 235);
}
.title-container3 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: -40px;
  background-color: rgb(235, 235, 235);
}
.account-title {
  align-items: center;
  justify-content: center;
  /* padding-right: 20px; */
  font-weight: 600;
  font-size: 20pt;
  background-color: rgb(235, 235, 235);
}
.account-title2 {
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  font-weight: 600;
  font-size: 16pt;
  background-color: rgb(235, 235, 235);
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-container2 {
  display: flex;
  justify-content: center;
  background-color: rgb(160, 160, 160);
  align-items: center;
}

.dropdown-select {
  color: black;
  position: relative;
  height: 50px;
  /* width: 40%; */

  border-radius: 6px;
  font-family: "Roboto";
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 20px;
  /* text-align-last:center; */
}
.sort-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background-color: blue; */
}
.red {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin-top: 5px;
}
.input {
  margin-left: 10px;
}
.button-add {
  color: rgb(255, 205, 0);
  background-color: rgb(0, 0, 0);
  border: 1px solid;
  border-radius: 5px;
  border-color: rgb(255, 205, 0);
  padding: 5px;
  margin-left: 10px;
  font-size: 18pt;
  font-weight: 700;
  cursor: pointer;
}
.separator {
  flex-grow: 1;
  border: none;
  height: 4px;
  background-color: rgb(61, 61, 61);
  width: 100%;
}
.center-block {
  width: 100%;
}
