.workspaces-header {
  border: 2px solid rgb(162, 107, 107);
  border-radius: 5px;
  color: rgb(255 205 0);
  padding: 15px;
  font: "Roboto";
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  background: black;
  margin-top: -10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  width: 80%;
}

.workspaces-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: -10px;
  min-width: 150px;
  background-color: white;
  border-left: solid;
  border-right: solid;
  border-color: rgb(158, 158, 158);
  padding: 20px;
}

.workspaces-title {
  font: "Roboto";
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.body-text {
  font-size: 20px;
}

.body-shape {
  position: static;
  border: 5px solid black;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  width: 1108px;
  float: left;
}
